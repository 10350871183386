<script setup lang="ts">
    import CpProductCardList from '~/components/cp/CpProductCard/CpProductCardList/CpProductCardList.vue';
    import type {IEtaInfo} from '~/types';

    const props = withDefaults (defineProps<{
        kind: 'FeaturedOffer'|'Standard'|'Offer'|'Featured'|'Oos'|'OosOffer',
        productType: 'physical'|'esd',
        pillLarge: boolean,
        pillShort: boolean,
        rowLarge?: boolean,
        rowShort?: boolean,
        rate: boolean,
        rateValue: number,
        reviews: number,
        freeShipping: boolean,
        link: string,
        title: string,
        hasDiscount: boolean,
        discount: string | boolean,
        picture: string,
        imageTitle: string,
        price: number,
        tPrice: number,
        id: string,
        eta: boolean,
        index?: number,
        dateStart?: number,
        dateEnd?: number,
        etaData?: IEtaInfo | { messages: IEtaInfo['messages'] }
    }>(), {
        eta: true,
    });
</script>

<template>
    <template v-if="typeof index === 'number' && index < 6">
        <div class="cp-product-card-catalog-stream" data-testid="stream-catalog-card">
            <CpProductCardList v-bind="props" />
        </div>
    </template>
    <v-lazy v-else-if="typeof index === 'number' && index > 24" :options="{threshold:0.1}" transition="fade-transition">
        <div class="cp-product-card-catalog-stream" data-testid="stream-catalog-card">
            <CpProductCardList v-bind="props" />
        </div>
    </v-lazy>
    <div v-else class="cp-product-card-catalog-stream" data-testid="stream-catalog-card">
        <CpProductCardList v-bind="props" />
    </div>
</template>

<style scoped lang="scss">
    .cp-product-card-catalog-stream {
        border: 1px solid var(--color-neutral-extra-light);
        border-radius: 8px;
        height: 166px;
        flex: 1;
    }
</style>
